import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import {
  IconButton,
  InputAdornment,
  TextField,
  Modal,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import ActionButton from "../../../components/ActionButton";
import ReactTable from "react-table";
import {
  getConsulenti,
  getConsulentiFiltered,
  getTotaleConsulenti,
} from "../../../utils/api/consulenti_api";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    boxShadow: theme.shadows[6],
    borderRadius: "6px",
    overflowY: "auto",
    height: "640px",
    width: "70%",
    maxWidth: "950px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  modalSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: theme.spacing(2),
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  modalTitle: {
    fontSize: 30,
    alignSelf: "center",
    width: "60%",
  },
});
const SelectTable = selectTableHOC(ReactTable);

class ModalSelezioneConsulente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consulenti: [],
      colonneConsulenti: [],
      consulentiIdArray: [],
      selectedConsulente: -1,
      valueToPassForFilter: "",
      emailToPassForFilter: "",
      nameToPassForFilter: "",
      cognomeToPassForFilter: "",
      elementiTotali: 0,
      loadedPage: 0,
      totalPages: 1,
      loading: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("superconsulente") === "true") {
      let a = 0;
      if (this.props.gruppoId != null) {
        getTotaleConsulenti(this.props.gruppoId)
          .then((result) => {
            a = result;
            this.setState({
              totalPages: Math.ceil(a / 10),
            });
            this.getConsulentiForTable(0);
          })
          .catch((error) => {
            if (error.status === 403) {
              this.props.handleInvalidToken();
            } else {
              this.props.handleError(true, error.message);
            }
          });
      }
    }
  }

  isNumber = (value) => {
    if (typeof value === "string") {
      return !isNaN(value);
    }
  };

  getConsulentiForTable = (page) => {
    getConsulenti(null, this.props.gruppoId, page, 10, "id")
      .then((result) => {
        if (result != null) {
          let newArray = this.state.consulenti;
          newArray = result;
          this.setState(
            {
              consulenti: newArray,
            },
            () => {
              let a = this.getConsulenteColumn(newArray);
              this.setState({
                colonneConsulenti: a,
                loading: false,
              });
            }
          );
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  //Loaded page will allow to load the selected table page instead of forcing the loading of the first one
  getConsulenteColumn = (data) => {
    return [
      {
        Header: "Id",
        id: "IdConsulente",
        width: 155,
        filterable: false,
        accessor: (data) => data.id,
      },
      {
        Header: "Email",
        id: "EmailConsulente",
        minWidth: 210,
        Filter: (cellInfo) => (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (this.isNumber(this.state.valueToPassForFilter)) {
                        if (this.state.emailToPassForFilter.length === 0) {
                          getConsulentiFiltered(
                            this.state.valueToPassForFilter,
                            this.props.gruppoId,
                            this.state.loadedPage,
                            100,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nameToPassForFilter,
                            this.state.cognomeToPassForFilter
                          )
                            .then((result) => {
                              if (this.state.consulentiIdArray.length === 0) {
                                this.setState({
                                  consulenti: result,
                                  selectedConsulente: -1,
                                  consulentiIdArray: [],
                                });
                              } else {
                                let item = result.find(
                                  (element) =>
                                    element.id ===
                                    this.state.consulentiIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: item.id,
                                    consulentiIdArray:
                                      this.state.consulentiIdArray,
                                  });
                                } else {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: -1,
                                    consulentiIdArray: [],
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        } else {
                          getConsulentiFiltered(
                            this.state.valueToPassForFilter,
                            this.props.gruppoId,
                            null,
                            null,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nameToPassForFilter,
                            this.state.cognomeToPassForFilter
                          )
                            .then((result) => {
                              let numberOfPages = Math.ceil(result.length / 10);
                              if (this.state.consulentiIdArray.length === 0) {
                                this.setState({
                                  consulenti: result,
                                  selectedConsulente: -1,
                                  consulentiIdArray: [],
                                  totalPages: numberOfPages,
                                });
                              } else {
                                let item = result.find(
                                  (element) =>
                                    element.id ===
                                    this.state.consulentiIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: item.id,
                                    consulentiIdArray:
                                      this.state.consulentiIdArray,
                                    totalPages: numberOfPages,
                                  });
                                } else {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: -1,
                                    consulentiIdArray: [],
                                    totalPages: numberOfPages,
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        }
                      } else {
                        alert("Attenzione, l'id deve avere un valore numerico");
                      }
                    }}
                  >
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={this.state.emailToPassForFilter}
            onInput={(event) => {
              this.setState({
                emailToPassForFilter: event.target.value,
              });
            }}
            onBlur={() => {
              if (this.isNumber(this.state.valueToPassForFilter)) {
                if (this.state.emailToPassForFilter.length === 0) {
                  getConsulentiFiltered(
                    this.state.valueToPassForFilter,
                    this.props.gruppoId,
                    this.state.loadedPage,
                    100,
                    "id",
                    this.state.emailToPassForFilter,
                    this.state.nameToPassForFilter,
                    this.state.cognomeToPassForFilter
                  )
                    .then((response) => {
                      getTotaleConsulenti(this.props.gruppoId).then(
                        (result) => {
                          this.setState({
                            consulenti: response,
                            totalPages: Math.ceil(result / 10),
                          });
                        }
                      );
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                }
              } else {
                alert("Attenzione, l'id deve avere un valore numerico");
              }
            }}
          ></TextField>
        ),
        accessor: (data) => data.email,
      },
      {
        Header: "Nome",
        id: "NomeConsulente",
        width: 180,
        Filter: (cellInfo) => (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (this.isNumber(this.state.valueToPassForFilter)) {
                        if (this.state.nameToPassForFilter.length === 0) {
                          getConsulentiFiltered(
                            this.state.valueToPassForFilter,
                            this.props.gruppoId,
                            this.state.loadedPage,
                            10,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nameToPassForFilter,
                            this.state.cognomeToPassForFilter
                          )
                            .then((result) => {
                              if (this.state.consulentiIdArray.length === 0) {
                                this.setState({
                                  consulenti: result,
                                  selectedConsulente: -1,
                                  consulentiIdArray: [],
                                });
                              } else {
                                let item = result.find(
                                  (element) =>
                                    element.id ===
                                    this.state.consulentiIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: item.id,
                                    consulentiIdArray:
                                      this.state.consulentiIdArray,
                                  });
                                } else {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: -1,
                                    consulentiIdArray: [],
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        } else {
                          getConsulentiFiltered(
                            this.state.valueToPassForFilter,
                            this.props.gruppoId,
                            null,
                            null,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nameToPassForFilter,
                            this.state.cognomeToPassForFilter
                          )
                            .then((result) => {
                              let numberOfPages = Math.ceil(result.length / 10);
                              if (this.state.consulentiIdArray.length === 0) {
                                this.setState({
                                  consulenti: result,
                                  selectedConsulente: -1,
                                  consulentiIdArray: [],
                                  totalPages: numberOfPages,
                                });
                              } else {
                                let item = result.find(
                                  (element) =>
                                    element.id ===
                                    this.state.consulentiIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: item.id,
                                    consulentiIdArray:
                                      this.state.consulentiIdArray,
                                    totalPages: numberOfPages,
                                  });
                                } else {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: -1,
                                    consulentiIdArray: [],
                                    totalPages: numberOfPages,
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        }
                      } else {
                        alert("Attenzione, l'id deve avere un valore numerico");
                      }
                    }}
                  >
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={this.state.nameToPassForFilter}
            onInput={(event) => {
              this.setState({
                nameToPassForFilter: event.target.value,
              });
            }}
            onBlur={() => {
              if (this.isNumber(this.state.valueToPassForFilter)) {
                if (this.state.nameToPassForFilter.length === 0) {
                  getConsulentiFiltered(
                    this.state.valueToPassForFilter,
                    this.props.gruppoId,
                    this.state.loadedPage,
                    10,
                    "id",
                    this.state.emailToPassForFilter,
                    this.state.nameToPassForFilter,
                    this.state.cognomeToPassForFilter
                  )
                    .then((response) => {
                      getTotaleConsulenti(this.props.gruppoId).then(
                        (result) => {
                          this.setState({
                            consulenti: response,
                            totalPages: Math.ceil(result / 10),
                          });
                        }
                      );
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                }
              } else {
                alert("Attenzione, l'id deve avere un valore numerico");
              }
            }}
          ></TextField>
        ),
        accessor: (data) => data.nome,
      },
      {
        Header: "Cognome",
        id: "CognomeConsulente",
        width: 180,
        Filter: (cellInfo) => (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (this.isNumber(this.state.valueToPassForFilter)) {
                        if (this.state.cognomeToPassForFilter.length === 0) {
                          getConsulentiFiltered(
                            this.state.valueToPassForFilter,
                            this.props.gruppoId,
                            this.state.loadedPage,
                            10,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nameToPassForFilter,
                            this.state.cognomeToPassForFilter
                          )
                            .then((result) => {
                              if (this.state.consulentiIdArray.length === 0) {
                                this.setState({
                                  consulenti: result,
                                  selectedConsulente: -1,
                                  consulentiIdArray: [],
                                });
                              } else {
                                let item = result.find(
                                  (element) =>
                                    element.id ===
                                    this.state.consulentiIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: item.id,
                                    consulentiIdArray:
                                      this.state.consulentiIdArray,
                                  });
                                } else {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: -1,
                                    consulentiIdArray: [],
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        } else {
                          getConsulentiFiltered(
                            this.state.valueToPassForFilter,
                            this.props.gruppoId,
                            null,
                            null,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nameToPassForFilter,
                            this.state.cognomeToPassForFilter
                          )
                            .then((result) => {
                              let numberOfPages = Math.ceil(result.length / 10);
                              if (this.state.consulentiIdArray.length === 0) {
                                this.setState({
                                  consulenti: result,
                                  selectedConsulente: -1,
                                  consulentiIdArray: [],
                                  totalPages: numberOfPages,
                                });
                              } else {
                                let item = result.find(
                                  (element) =>
                                    element.id ===
                                    this.state.consulentiIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: item.id,
                                    consulentiIdArray:
                                      this.state.consulentiIdArray,
                                    totalPages: numberOfPages,
                                  });
                                } else {
                                  this.setState({
                                    consulenti: result,
                                    selectedConsulente: -1,
                                    consulentiIdArray: [],
                                    totalPages: numberOfPages,
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        }
                      } else {
                        alert("Attenzione, l'id deve avere un valore numerico");
                      }
                    }}
                  >
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={this.state.cognomeToPassForFilter}
            onInput={(event) => {
              this.setState({
                cognomeToPassForFilter: event.target.value,
              });
            }}
            onBlur={() => {
              if (this.isNumber(this.state.valueToPassForFilter)) {
                if (this.state.cognomeToPassForFilter.length === 0) {
                  getConsulentiFiltered(
                    this.state.valueToPassForFilter,
                    this.props.gruppoId,
                    this.state.loadedPage,
                    10,
                    "id",
                    this.state.emailToPassForFilter,
                    this.state.nameToPassForFilter,
                    this.state.cognomeToPassForFilter
                  )
                    .then((response) => {
                      getTotaleConsulenti(this.props.gruppoId).then(
                        (result) => {
                          this.setState({
                            consulenti: response,
                            totalPages: Math.ceil(result / 10),
                          });
                        }
                      );
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                }
              } else {
                alert("Attenzione, l'id deve avere un valore numerico");
              }
            }}
          ></TextField>
        ),
        accessor: (data) => data.cognome,
      },
    ];
  };

  toggleSelection = (key, shift, row) => {
    this.setState({
      selectedConsulente: key.split("select-")[1],
    });
    let consulentiIdArray = [...this.state.consulentiIdArray];
    const keyIndex = consulentiIdArray.indexOf(key);
    if (keyIndex >= 0) {
      consulentiIdArray = [
        ...consulentiIdArray.slice(0, keyIndex),
        ...consulentiIdArray.slice(keyIndex + 1),
      ];
    } else {
      consulentiIdArray = [];
      consulentiIdArray.push(row);
    }
    this.setState({ consulentiIdArray });
  };
  isSelected = (key) => {
    if (this.state.consulentiIdArray.some((e) => e.id === key)) {
      return true;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal
        open={this.props.isModalOpen}
        onClose={this.props.closeModal}
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalSectionContainer}>
            <Typography className={classes.modalTitle}>
              Selezionare il consulente da associare al nuovo manuale
            </Typography>
            <SelectTable
              style={{ width: "85%", alignSelf: "center" }}
              selectType="radio"
              ref={(r) => {
                this.checkboxTable = r;
              }}
              toggleSelection={this.toggleSelection}
              isSelected={this.isSelected}
              keyField={"id"}
              filterable={true}
              resizable={true}
              showPageSizeOptions={false}
              showPageJump={false}
              showPaginationBottom={true}
              defaultPageSize={10}
              loading={this.state.loading}
              pages={this.state.totalPages}
              onPageChange={(pageIndex, state) => {
                this.setState({
                  loadedPage: pageIndex,
                });
              }}
              manual
              onFetchData={(state, instance) => {
                this.setState({
                  loading: true,
                });
                let a = 0;
                if (
                  this.props.gruppoId != null &&
                  state.filtered[0] === undefined
                ) {
                  getTotaleConsulenti(this.props.gruppoId)
                    .then((result) => {
                      a = result;
                      this.setState({
                        totalPages: Math.ceil(a / state.pageSize),
                      });
                    })
                    .then((result) => {
                      getConsulenti(
                        null,
                        this.props.gruppoId,
                        state.page,
                        state.pageSize,
                        "id"
                      ).then((result) => {
                        if (result != null) {
                          let newArray = this.state.consulenti;
                          newArray = result;
                          this.setState(
                            {
                              consulenti: newArray,
                            },
                            () => {
                              let a = this.getConsulenteColumn(newArray);
                              this.setState({
                                colonneConsulenti: a,
                                loading: false,
                              });
                            }
                          );
                        }
                      });
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                  this.setState({
                    loading: false,
                  });
                } else {
                  this.setState({
                    loading: false,
                  });
                }
              }}
              columns={this.state.colonneConsulenti}
              data={this.state.consulenti}
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun Consulente Trovato"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ActionButton
                onClick={this.props.closeModal}
                label="Annulla"
                disabled={false}
                grayVersion={false}
                isRed={true}
              />
              <ActionButton
                onClick={() => {
                  this.props.openEserciziModal(this.state.selectedConsulente);
                }}
                label="Prosegui"
                disabled={false}
                grayVersion={false}
              />
              {/*<GoToCreazioneManualeButton gruppoId={this.props.gruppoId} handleError={this.props.handleError} handleInvalidToken={this.props.handleInvalidToken} mode="Manuale" selectedConsulente={this.state.selectedConsulente} path={"/testWork/nuovoManuale"} label="Prosegui"></GoToCreazioneManualeButton>*/}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ModalSelezioneConsulente);
