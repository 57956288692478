import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { Search } from "@material-ui/icons";
import ReactTable from "react-table";
// React-table library requires to import css as well:
import "react-table/react-table.css"; // eslint-disable-next-line
import ActionButton from "../../../components/ActionButton";
import {
  getCountEserciziCommerciali,
  getEserciziCommerciali,
  getEserciziCommercialiFiltered,
  getEsercizioCommerciale,
} from "../../../utils/api/esercizi_commerciali_api";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    boxShadow: theme.shadows[6],
    borderRadius: "6px",
    overflowY: "auto",
    height: "640px",
    width: "70%",
    maxWidth: "950px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  modalSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  modalTitle: {
    fontSize: 30,
    width: "60%",
    alignSelf: "center",
  },
});
const SelectTable = selectTableHOC(ReactTable);

class ModalSelezioneEsercizioCommerciale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colonneEsercizi: [],
      eserciziIdArray: [],
      selectedEsercizio: -1,
      elementiTotali: 0,
      loadedPage: 0,
      totalPages: 1,
      loading: true,
      esercizi: [],
      idToPassForFilter: "",
      emailToPassForFilter: "",
      nomeToPassForFilter: "",
    };
  }

  componentDidMount() {
    let a = 0;
    if (this.props.gruppoId != null) {
      getCountEserciziCommerciali(this.props.gruppoId, this.props.idConsulente)
        .then((result) => {
          a = result;
          this.setState({
            totalPages: Math.ceil(a / 10),
          });
        })
        .then((result) => {
          this.getEserciziForTable(0);
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    }
  }

  isNumber = (value) => {
    if (typeof value === "string") {
      return !isNaN(value);
    }
  };

  getEserciziForTable = (page) => {
    getEserciziCommerciali(
      this.props.gruppoId,
      this.props.idConsulente,
      null,
      page,
      10,
      "id"
    )
      .then((result) => {
        if (result != null) {
          let newArray = this.state.esercizi;
          newArray = result;
          this.setState(
            {
              esercizi: newArray,
            },
            () => {
              let a = this.getEserciziColumn(newArray);
              this.setState({
                colonneEsercizi: a,
                loading: false,
              });
            }
          );
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  getEserciziColumn = (data) => {
    return [
      {
        Header: "Id",
        id: "IdEsercizio",
        filterable: false,
        accessor: (data) => data.id,
      },
      {
        Header: "Email",
        id: "EmailEsercizio",
        minWidth: 240,
        Filter: (cellInfo) => (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      if (this.isNumber(this.state.idToPassForFilter)) {
                        if (this.state.emailToPassForFilter.length === 0) {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            this.state.loadedPage,
                            100,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        } else {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            null,
                            null,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              let numberOfPages = Math.ceil(
                                response.length / 10
                              );
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                  totalPages: numberOfPages,
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                    totalPages: numberOfPages,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                    totalPages: numberOfPages,
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        }
                      } else {
                        alert("Attenzione, l'id deve avere un valore numerico");
                      }
                    }}
                    size="small"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={this.state.emailToPassForFilter}
            onInput={(event) => {
              this.setState({
                emailToPassForFilter: event.target.value,
              });
            }}
            onBlur={() => {
              if (this.state.emailToPassForFilter.length === 0) {
                if (this.state.idToPassForFilter.length !== 0) {
                  if (this.isNumber(this.state.idToPassForFilter)) {
                    let arrayOfResults = [];
                    getEsercizioCommerciale(this.state.idToPassForFilter)
                      .then((response) => {
                        arrayOfResults.push(response.esercizio);
                        this.setState({ esercizi: arrayOfResults });
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.props.handleError(
                            true,
                            "Errore, elemento non trovato"
                          );
                        } else {
                          this.props.handleError(true, error.message);
                        }
                      });
                  } else {
                    alert(
                      "Attenzione, l'id passato deve avere un valore numerico"
                    );
                  }
                } else {
                  getEserciziCommercialiFiltered(
                    this.props.gruppoId,
                    this.props.idConsulente,
                    null,
                    this.state.loadedPage,
                    100,
                    "id",
                    this.state.emailToPassForFilter,
                    this.state.nomeToPassForFilter
                  )
                    .then((response) => {
                      getCountEserciziCommerciali(
                        this.props.gruppoId,
                        this.props.idConsulente
                      ).then((result) => {
                        let numberOfPages = Math.ceil(result / 10);
                        this.setState({
                          esercizi: response,
                          totalPages: numberOfPages,
                        });
                      });
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                }
              }
            }}
          />
        ),
        accessor: (data) => data.eMailEsercizio,
      },
      {
        Header: "Ragione sociale",
        id: "NomeEsercizio",
        minWidth: 210,
        Filter: (cellInfo) => (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      if (this.isNumber(this.state.idToPassForFilter)) {
                        if (this.state.nomeToPassForFilter.length === 0) {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            this.state.loadedPage,
                            10,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        } else {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            null,
                            null,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              let numberOfPages = Math.ceil(
                                response.length / 10
                              );
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                  totalPages: numberOfPages,
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                    totalPages: numberOfPages,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                    totalPages: numberOfPages,
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        }
                      } else {
                        alert("Attenzione, l'id deve avere un valore numerico");
                      }
                    }}
                    size="small"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={this.state.nomeToPassForFilter}
            onInput={(event) => {
              this.setState({
                nomeToPassForFilter: event.target.value,
              });
            }}
            onBlur={() => {
              if (this.state.nomeToPassForFilter.length === 0) {
                if (this.state.idToPassForFilter.length !== 0) {
                  if (this.isNumber(this.state.idToPassForFilter)) {
                    let arrayOfResults = [];
                    getEsercizioCommerciale(this.state.idToPassForFilter)
                      .then((response) => {
                        arrayOfResults.push(response.esercizio);
                        this.setState({ esercizi: arrayOfResults });
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.props.handleError(
                            true,
                            "Errore, elemento non trovato"
                          );
                        } else {
                          this.props.handleError(true, error.message);
                        }
                      });
                  } else {
                    alert("Attenzione,l'id deve avere un valore numerico");
                  }
                } else {
                  getEserciziCommercialiFiltered(
                    this.props.gruppoId,
                    this.props.idConsulente,
                    null,
                    this.state.loadedPage,
                    100,
                    "id",
                    this.state.emailToPassForFilter,
                    this.state.nomeToPassForFilter
                  )
                    .then((response) => {
                      getCountEserciziCommerciali(
                        this.props.gruppoId,
                        this.props.idConsulente
                      ).then((result) => {
                        let numberOfPages = Math.ceil(result / 10);
                        this.setState({
                          esercizi: response,
                          totalPages: numberOfPages,
                        });
                      });
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                }
              }
            }}
          ></TextField>
        ),
        accessor: (data) => data.ragioneSociale,
      },
    ];
  };

  toggleSelection = (key, shift, row) => {
    this.setState({
      selectedEsercizio: key.split("select-")[1],
    });
    let eserciziIdArray = [...this.state.eserciziIdArray];
    const keyIndex = eserciziIdArray.indexOf(key);
    if (keyIndex >= 0) {
      eserciziIdArray = [
        ...eserciziIdArray.slice(0, keyIndex),
        ...eserciziIdArray.slice(keyIndex + 1),
      ];
    } else {
      eserciziIdArray = [];
      eserciziIdArray.push(row);
    }
    this.setState({ eserciziIdArray });
  };
  isSelected = (key) => {
    if (this.state.eserciziIdArray.some((e) => e.id === key)) {
      return true;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal
        open={this.props.isModalOpen}
        onClose={this.props.closeModal}
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalSectionContainer}>
            <Typography className={classes.modalTitle}>
              Selezionare il punto vendita da associare al nuovo manuale
            </Typography>
            <SelectTable
              style={{ width: "85%", alignSelf: "center" }}
              selectType="radio"
              ref={(r) => {
                this.checkboxTable = r;
              }}
              toggleSelection={this.toggleSelection}
              isSelected={this.isSelected}
              keyField={"id"}
              filterable={true}
              resizable={true}
              showPageSizeOptions={false}
              showPageJump={false}
              showPaginationBottom={true}
              defaultPageSize={10}
              loading={this.state.loading}
              pages={this.state.totalPages}
              onPageChange={(pageIndex) => {
                this.setState({
                  loadedPage: pageIndex,
                });
              }}
              manual
              onFetchData={(state, instance) => {
                this.setState({
                  loading: true,
                });
                let a = 0;
                if (this.props.gruppoId != null) {
                  getCountEserciziCommerciali(
                    this.props.gruppoId,
                    this.props.idConsulente
                  )
                    .then((result) => {
                      a = result;
                      this.setState({
                        totalPages: Math.ceil(a / state.pageSize),
                      });
                    })
                    .then((result) => {
                      getEserciziCommerciali(
                        this.props.gruppoId,
                        this.props.idConsulente,
                        null,
                        state.page,
                        state.pageSize,
                        "id"
                      ).then((result) => {
                        if (result != null) {
                          let newArray = this.state.esercizi;
                          newArray = result;
                          this.setState(
                            {
                              esercizi: newArray,
                            },
                            () => {
                              let a = this.getEserciziColumn(newArray);
                              this.setState({
                                colonneEsercizi: a,
                                loading: false,
                              });
                            }
                          );
                        }
                      });
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                  this.setState({
                    loading: false,
                  });
                }
              }}
              columns={this.state.colonneEsercizi}
              data={this.state.esercizi}
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun punto vendita Trovato"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ActionButton
                onClick={this.props.closeModal}
                isRed={true}
                label="Annulla"
                disabled={false}
                grayVersion={false}
              />
              <ActionButton
                label="Prosegui"
                disabled={false}
                grayVersion={false}
                onClick={() => {
                  if (this.state.selectedEsercizio !== -1) {
                    this.props.openModalitaModal(
                      this.props.idConsulente,
                      this.state.selectedEsercizio
                    );
                  } else {
                    alert("Attenzione, occorre selezionare un punto vendita");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ModalSelezioneEsercizioCommerciale);
